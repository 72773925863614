const widget = document.getElementsByClassName('widget-svg');

if (widget.length > 0) {
  $.request('flatsList::onGetFlatsJson', {
    success: function (data) {
      const flats = JSON.parse(data.result);
      const houses = [...document.getElementsByClassName('widget-svg__house')];
      const houseBox = document.getElementsByClassName('widget-svg__box')[0];
      const houseBoxName = document.getElementsByClassName('widget-svg__name')[0];
      const houseBoxStatus = document.getElementsByClassName('widget-svg__status')[0];
      const houseBoxArea = document.getElementsByClassName('widget-svg__area')[0];
      const houseBoxFinishYear = document.getElementsByClassName('widget-svg__year')[0];
      const siteWrapper = document.getElementsByClassName('site-wrapper')[0];
      siteWrapper.appendChild(houseBox);

      const activeHouseID = widget[0].getAttribute('data-active-house');
      if(activeHouseID){
        const activeHouseItem = flats.filter(el => el.id == activeHouseID)[0];
        const activeHouseHtml = document.getElementById(activeHouseItem.symbol);
        activeHouseHtml.classList.add('active');
      }

      houses.forEach(house => {
        house.addEventListener('click', function (e) {
          e.preventDefault();
          const houseID = this.getAttribute('id');
          const houseItem = flats.filter(el => el.symbol == houseID)[0];
          window.location = `/domy/${houseItem.id}/${houseItem.slug}`;
        });

        house.addEventListener('mouseenter', function (e) {
          e.preventDefault();
          houseBox.style.transform = 'scale(0)';
          houseBox.style.opacity = '0';

          const houseID = this.getAttribute('id');
          const houseItem = flats.filter(el => el.symbol == houseID)[0];

          houseBoxName.innerText = houseItem.name;
          houseBoxStatus.innerText = houseItem.status;
          houseBoxArea.innerText = houseItem.area;
          houseBoxFinishYear.innerText = houseItem.finish_year;

          houseBox.style.transform = 'scale(1)';

          const left = parseInt(e.clientX) + "px";
          const top = parseInt(this.getBoundingClientRect().top - houseBox.getBoundingClientRect().height + window.pageYOffset) + 'px';

          houseBox.style.opacity = '1';
          houseBox.style.left = left;
          houseBox.style.top = top;

        });

        house.addEventListener('mouseleave', function (e) {
          e.preventDefault();
          if(e.toElement.getAttribute('class') !== 'widget-svg__box'){
            houseBox.style.transform = 'scale(0)';
            houseBox.style.opacity = '0';
            houseBox.addEventListener('mouseleave', function (e) {
              e.preventDefault();
              houseBox.style.transform = 'scale(0)';
              houseBox.style.opacity = '0';
            });
          }
        });
      });
    }
  });
}
